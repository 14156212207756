<template>
  <div class="addPeriodical">
    <el-form
      v-if="isShow"
      :model="addPeriodicalObj"
      :rules="addPeriodicalRules"
      ref="addPeriodicalRef"
      label-width="120px"
    >
      <el-form-item label="论文年份：">
        <data-time
          @dateChange="dateChange"
          :parentYear="addPeriodicalObj.yearsTime"
          :parentMonth="addPeriodicalObj.monthTime"
          :parentDay="addPeriodicalObj.dayTime"
        />
      </el-form-item>
      <el-form-item label="举办城市：">
        <cascader-area
          :country="addPeriodicalObj.country"
          :province="addPeriodicalObj.province"
          :city="addPeriodicalObj.city"
          @searchCountry="searchCountry"
          @searchProvince="searchProvince"
          @searchCity="searchCity"
        />
      </el-form-item>
      <el-form-item label="全称：">
        <el-input v-model="addPeriodicalObj.fullName" class="lager-input" />
      </el-form-item>
      <el-form-item label="论文文件：">
        <upload-file
          :saveUrl.sync="addPeriodicalObj.paperFile"
          :lookUrl.sync="addPeriodicalObj.paperFile"
          ref="uploadFile"
        />
      </el-form-item>
      <el-form-item label="论文简介：">
        <el-input
          v-model="addPeriodicalObj.introduction"
          class="lager-input"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 20 }"
        />
      </el-form-item>
      <el-form-item label="会议/刊期：" class="radio_common">
        <dic-radio-group :code.sync="addPeriodicalObj.journal" type-code="026" />
      </el-form-item>
      <el-form-item label="技术类别：">
        <dic-checkbox-group
          :code.sync="addPeriodicalObj.technologyCategory"
          tag="技术"
          @updateCode="updateCode"
        />
      </el-form-item>
      <el-form-item label="关键词：">
        <el-input
          v-model="addPeriodicalObj.keywords"
          class="lager-input"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 20 }"
        />
      </el-form-item>
    </el-form>
    <extra-info />
    <div class="bottom">
      <el-button type="primary" @click="submit">保存</el-button>
      <el-button @click="cancelItem">取消</el-button>
    </div>
  </div>
</template>

<script>
import CascaderArea from '@/components/CascaderArea.vue'
import UploadFile from '@/components/UploadFile.vue'
import ExtraInfo from '@/components/ExtraInfo.vue'
import DicCheckboxGroup from '@/components/DicCheckboxGroup.vue'
import DataTime from '@/components/DataTime.vue'
import DicRadioGroup from '@/components/DicRadioGroup.vue'
import { addPeriodical, updatePeriodical, getPeriodicalById } from '@/api/paper'
export default {
  name: 'AddPeriodical',

  components: {
    ExtraInfo,
    UploadFile,
    DicCheckboxGroup,
    CascaderArea,
    DataTime,
    DicRadioGroup,
  },

  data() {
    return {
      addPeriodicalObj: {
        yearsTime: null, // 年
        monthTime: null, // 月
        dayTime: null, // 日
        country: '', // 国家
        province: '', // 省份
        city: '', // 城市
        fullName: '', // 全称
        paperFile: '', // 论文文件
        introduction: '', // 论文简介
        journal: '', // 会议/刊期
        technologyCategory: [], // 技术类别
        technologyChildrenId: [], // 技术类别子标签
        keywords: '', // 关键词
      },
      isShow: true,
      addPeriodicalRules: {},
      query: {
        periodical: [],
      },
    }
  },

  computed: {
    periodicalType() {
      return Number(this.$route.query.periodicalType)
    },
    id() {
      return this.$route.query.id
    },
  },

  watch: {
    'addPeriodicalObj.paperFile': {
      handler(newVal) {
        if (this.periodicalType && newVal) {
          this.isShow = true
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    if (this.periodicalType) {
      this.search()
    }
  },

  methods: {
    async search() {
      const res = await getPeriodicalById({ id: this.id })

      if (res.code === 200) {
        this.addPeriodicalObj = res.data

        this.addPeriodicalObj.technologyCategory = this.formatString(
          this.addPeriodicalObj.technologyCategory
        )


        this.addPeriodicalObj.yearsTime = this.formatNumber(
          this.addPeriodicalObj.yearsTime
        )
        this.addPeriodicalObj.monthTime = this.formatNumber(
          this.addPeriodicalObj.monthTime
        )
        this.addPeriodicalObj.dayTime = this.formatNumber(
          this.addPeriodicalObj.dayTime
        )
      }
    },
    // 将字符串格式化为数字
    formatNumber(val) {
      if (val) {
        return Number(val)
      } else {
        return val
      }
    },
    dateChange(year, month, day) {
      this.addPeriodicalObj.yearsTime = year

      this.addPeriodicalObj.monthTime = month

      this.addPeriodicalObj.dayTime = day
    },
    searchCountry(val) {
      this.addPeriodicalObj.country = val
    },
    searchProvince(val) {
      this.addPeriodicalObj.province = val
    },
    searchCity(val) {
      this.addPeriodicalObj.city = val
    },
    updateCode(val, tag) {
      switch (tag) {
        case '技术':
          this.addPeriodicalObj.technologyChildrenId = val
          break

        default:
          break
      }
    },
    submit() {
      this.$refs.addPeriodicalRef.validate(async (valid) => {
        if (valid) {
          if (this.addPeriodicalObj.technologyChildrenId) {
            this.addPeriodicalObj.technologyCategory =
              this.addPeriodicalObj.technologyCategory.concat(
                this.addPeriodicalObj.technologyChildrenId
              )
          }
          this.addPeriodicalObj.technologyCategory = [
            ...new Set(this.addPeriodicalObj.technologyCategory),
          ]
          let res
          if (this.periodicalType) {
            Object.assign(this.addPeriodicalObj, { id: this.id })
            res = await updatePeriodical(this.addPeriodicalObj)
          } else {
            res = await addPeriodical(this.addPeriodicalObj)
          }

          if (res.code === 200) {
            this.$message.success(
              `${this.periodicalType ? '编辑' : '新增'}成功`
            )

            this.$router.go(-1)
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    cancelItem() {
      this.$confirm('确认退出吗 ?', '提示')
        .then(() => {
          this.$router.go(-1)
        })
        .catch(() => {})
    },
    // 将字符串格式化成数组
    formatString(val) {
      if (!val) {
        return []
      } else if (!val.length) {
        return val
      } else {
        return val.split(',')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.addPeriodical {
  padding: 18px;
  background: #fff;
}
</style>
